
















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
    HomeBanner: () => import('@/components/base/mobile/InsBanner.vue'),
    EdCatalogsPromotion: () => import('@/components/edBusiness/mobile/home/EdCatalogsPromotion.vue'),
    EdProductPromotion: () => import('@/components/edBusiness/mobile/home/EdProductPromotion.vue'),
    EdMonterssori: () => import('@/components/edBusiness/mobile/home/EdMonterssori.vue')
  }
})
export default class InsHome extends Vue {
  // content: string =
  //   "<p style='text-align: justify;'><span style='font-size: 18px;'>泰美科技為不同行業提供優質可靠的網上平台及系統建立服務，包括手機版網站、 網上商店系統、 網上貿易系統、 合法電郵群發系統、 網上收款系統、 互聯網推廣及 寄存服務等。泰美科技成立超過19年，開業至今已為50個不同行業，超過6,000間大中小型企業、跨國公司、政府部門及慈善機構提供優質網上服務。</span><br/></p>";

  swiperOption: object = {
    loop: true,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  };

  mounted () {

  }
}
